<template>
  <div class="page" @click="hideBox">
    <div class="page-content">
      <gl-nav :title="title" @goCore="handleCore" v-if="isNavShow"/>
      <gl-header ref="glHeader" :isScrollIng="isScrollIng" 
      :active="active" :title="title" @goCore="handleCore"/>
      <slot @goCore="handleCore"/>
    </div>
  </div>
</template>
<script>
import GlHeader from './header.vue'
import GlNav from './nav.vue'
import { mapState } from 'vuex'
export default {
  name: 'PageScroll',
  props: {
    title: String,
  },
  components: {
    GlHeader,
    GlNav,
  },
  computed: {
    ...mapState(['isNavShow', 'isMobile', 'activeIndex']),
  },
  data() {
    return {
      isScrollIng: false,
      headerHight: 0,
      active: 0,
    }
  },
  mounted() {
    window.onscroll = this.throttle(() => {
      this.handleScroll()
    }, 10)
    let headerId = document.getElementById('Header')
    this.headerHight = headerId.offsetHeight
    if (this.title == 'home' && this.activeIndex !== 0) {
      this.handleCore(this.activeIndex)
    }
  },
  methods: {
    throttle(fn, delay) {
      let flag = true
      return () => {
        if (flag) {
          setTimeout(() => {
            fn()
            flag = true
          }, delay)
        }
        flag = false
      }
    },
    handleScroll() {
      // 距顶部
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 可视区高度
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      // 滚动条总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      if (scrollTop > 0) {
        this.isScrollIng = true
      } else {
        this.isScrollIng = false
      }
    },
    handleCore(num) {
      let reAbout = document.getElementById('About')
      let reVision = document.getElementById('Vision')
      let reService = document.getElementById('Service')
      let reProduct = document.getElementById('Product')
      let val = 0
      if (num == 1) {
        val = reAbout.offsetTop - this.headerHight
      } else if (num == 2) {
        val = reVision.offsetTop - this.headerHight
      } else if (num == 3) {
        val = reService.offsetTop - this.headerHight
      } else {
        val = reProduct.offsetTop - this.headerHight
      }
      document.documentElement.scrollTop = val
      document.body.scrollTop = val
    },
    // hideBox() {
    //   if (this.$refs && this.$refs.glHeader) this.$refs.glHeader.hidePopup()
    // }
  },
}
</script>
<style lang="less" scoped>
</style>
