
import { createI18n } from 'vue-i18n'
import messages from './index'
if(localStorage.getItem('language')== null){
  localStorage.setItem('language','zh_TW');
}
let lang = localStorage.getItem('language');
const i18n = createI18n({
  locale: lang,
  fallbackLocale: 'zh_TW',
  messages,
  silentTranslationWarn: true
})
export default i18n
