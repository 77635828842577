
const zh = {
	title: {
		home: '首頁',
	},
	nav: {
		desc: '關於',
		desc1: '願景',
		desc2: '服務',
		desc3: '核心產品',
		desc4: '隱私政策',
		desc5: '選擇語言',
		desc6: '',
		desc7: '',
		desc8: '',
		desc9: '',
		desc10: '',
	},
	home: {
		desc: 'SkyDAO Trust總部設於香港，是在香港註冊成立並根據《受託人條例》註冊的信託公司。SkyDAO Trust持有香港公司註冊處頒發的信託或公司服務提供者（TCSP）牌照。',
		desc1: 'SkyDAO Trust是⼀家獨立的信託公司，致⼒為來⾃世界各地的客⼾提供優質的服務。 SkyDAO Trust的管理團隊由信託⽅⾯的專業⼈⼠組成。我們在各個專業領域的豐富經驗，我們有能⼒並致⼒為客⼾提供真正的量⾝定製的⼀站式解決⽅案。我們的團隊成員都是受過良好的教育、誠實、有道德和值得信賴。',
		desc2: '廣泛經驗',
		desc3: '我們在廣泛的專業領域中的豐富知識使我們能夠照顧到客⼾的各個⽅⾯，並為他們提供⼀站式解決⽅案。',
		desc4: '專業資格',
		desc5: '我們的團隊成員擁有許多專業資格，例如CTP（認證信託從業者）、CWM（特許財富經理）、RFC（註冊財務顧問）、CPA（註冊會計師）、CFA（特許財經分析師）、ABV（認可企業評估師），等等。',
		desc6: '保持⻑期關係',
		desc7: '我們的⽅式是通過展⽰我們的誠信和細⼼的服務來與客⼾保持⻑期關係。我們的宗旨是認真聽取客⼾的意⾒，並協助他們利⽤信託來保存其資產並保護其家族成員，⽽同時其隱私將受到⾼度重視。',
		desc8: '讓資產配置更豐富',
		desc9: '讓投資決策更高效',
		desc10: '讓資產流動更自由',
		desc11: '讓投資使用者更安心',
		desc12: '讓資產交易更公平',
		desc13: '讓資產安全領先全球',
		desc14: '信託服務',
		desc15: '我們協助個⼈和公司建⽴各種信託，並可以作為信託的受託⼈',
		desc16: '建立不同種類的信託',
		desc17: '我們幫助客⼾根據他們的需求和條件建⽴定製的信託。在信託建⽴過程中，我們將考慮許多問題，例如參與信託的各⽅的KYC（了解你的客⼾）核查、信託成⽴⼈的意願、信託類型、信託結構、潛在的稅務負擔、財產所在地，等等。我們有能⼒為客⼾提供綜合了信託實體的解決⽅案。',
		desc18: '擔任受託⼈',
		desc19: '作為受託⼈，我們將僅以受益⼈的利益為依歸，按照信託契約並參考意願書來管理客⼾的信託。',
		desc20: '第三方數字錢包支付通道服務',
		desc21: '支持用戶間直接的P2P交易，資產只在用戶手中，真正自己把握自己',
		desc22: '擔任託管代理⼈',
		desc23: '作為託管代理人，我們在交易開始之前以信託方式持有財產。當相關各方均履行了交易的義務或條件時，託管資產將根據相關協議予以釋放。我們的獨立角色可以促進交易順利完成。',
		desc24: '信用卡資產信託',
		desc25: '支持與法幣互通的信用卡交易，真正打通加密金融與傳統金融的壁壘',
		desc26: 'XTM全球資產信託管理平台',
		desc27: 'SkyDAO Trust官方金融平台',
		desc28: '國家',
		desc29: '交易所',
		desc30: '交易品種',
		desc31: '交易系統',
		desc32: '傳統金融TradFi',
		desc33: '鏈上去中心化金融DeFi',
		desc34: '核心產品',
		desc35: '信託服務',
		desc36: '信託不是一家法人實體，它是一種法律安排，通過這種安排，資產從一個人（信託成立人）轉移給另一個人（受託人），而該受託人為了指定名單中或某些指定類別人士（受益人）的利益或某些特定目的而持有該等財產。',
		desc37: '遺產規劃',
		desc38: '員⼯福利',
		desc39: '投資',
		desc40: '稅務籌劃',
		desc41: '信託成⽴⼈可以為其家庭成員的利益建⽴信託。信託資產產⽣的收⼊或利益和信託資產本⾝將分配給受益⼈或根據信託契約進⾏處理。信託還可以⽤於保護不諳財務的受益⼈，並為揮霍者進⾏財務安排。',
		desc42: '為了吸引、激勵和獎勵員⼯，可以建⽴信託來獲取和持有公司的股份或其他資產，以使公司的員⼯受益。',
		desc43: '可以在信託中建⽴投資框架，以確保信託資產得到有系統的投資並由適當的專業⼈員處理。',
		desc44: '通過使⽤結構合理的信託並在適當的時間將資產轉讓給信託，有機會可以將各種稅項（例如遺產稅，繼承稅和印花稅）降⾄最低。',
		desc45: '常见的信托',
		desc46: '為鏈上資產安全保駕護航',
		desc47: '安全',
		desc48: '用戶在以太坊區塊鏈上使用自己的智能合約錢包保持安全和匿名。用戶擁有自己的私鑰，可以完全控制他們在平台中的資產。',
		desc49: '透明',
		desc50: '使用者的智能合約帳戶在區塊鏈（例如Etherscan.io網站）上清晰可見。與集中式交易所不同，交易者的存款不會集中存放在交易所控制的一個或幾個大型錢包中。',
		desc51: '保障',
		desc52: '用戶的資金完全透明，保障每筆交易的償付能力，並在區塊鏈上執行結算，結算後的資金保留在用戶帳戶中。',
		desc53: 'SkyDAO虛擬信用卡',
		desc54: '一卡在手，縱享Fiat與Crypto的無縫對接',
		desc55: '靈活便捷',
		desc56: '靈活使用多元資產',
		desc57: '在平台上方便還款',
		desc58: '全球通行',
		desc59: '隨時隨地提取現金',
		desc60: '靈活運用平台上多元資產',
		desc61: '安全放心',
		desc62: '2FA身份驗證，生物密碼加持',
		desc63: '多重認證保障用卡安全',
		desc64: '投資者輕鬆接入，全球金融市場運籌帷幄',
		desc65: '私⼈信託',
		desc66: '私⼈信託是受益⼈（接收信託資產分配的指定個⼈或實體）為私⼈且⾮慈善的信託。建⽴私⼈信託主要是為了家族傳承、資產保護和避免遺囑認證。',
		desc67: '⾸次公開募股前創始⼈股份信託',
		desc68: '⾸次公開募股前創始⼈股份信託降低了公司的⾸次公開發⾏⾵險。信託持有的股份可以使不同的家庭成員和幾代⼈受益。信託提供的優勢包括避免遺囑認證、資產保護、傳承計劃和股權擁有⼈的保密性。',
		desc69: '慈善信託',
		desc70: '慈善信託是⼀種致⼒於慈善⽬的和公益的信託形式。慈善⽬的通常包括減輕貧困的信託、促進教育的信託、促進宗教的信託，以及對社會有益的其他⽬的的信託。',
		desc71: '僱員受益信託',
		desc72: '僱員受益信託（EBT）是為了獲得和持有資產以保障公司僱員利益⽽設⽴的信託。由於信託資產與成⽴⼈的債權⼈之間被分隔，⽽且受託⼈獨⽴於公司，因此僱員受益信託可以為員⼯提供安全感。僱員受益信託的⽬的是吸引、激勵和獎勵公司的合資格員⼯。',
		desc73: '備⽤信託',
		desc74: '備⽤信託是成⽴⼈在世期間建⽴的信託，在信託成⽴時，資產注⼊計劃、受益⼈清單和分配計劃都已預先設定。但是，備⽤信託將⼀直處於”待命”模式，直到發⽣某些特定事件（例如信託成⽴⼈過世或精神上無⾏為能⼒），這將觸發將成⽴⼈資產注⼊信託。',
		desc75: '保險信託',
		desc76: '設⽴⼈可以建⽴信託並將信託指定為其保險單的持有⼈和受益⼈，⼀旦發⽣觸發索賠事件，信託將收到保險公司的賠付。然後，受託⼈將根據信託契約管理和分配信託資產。',
		desc77: '',
		desc78: '',
	},
	policy: {
		desc: '',
		desc1: '',
		desc2: '',
		desc3: '',
		desc4: '',
		desc5: '',
		desc6: '',
		desc7: '',
		desc8: '',
		desc9: '',
		desc10: '',
		desc11: '',
		desc12: '',
		desc13: '',
		desc14: '',
		desc15: '',
		desc16: '',
		desc17: '',
		desc18: '',
		desc19: '',
		desc20: '',
		desc21: '',
		desc22: '',
		desc23: '',
		desc24: '',
		desc25: '',
		desc26: '',
		desc27: '',
		desc28: '',
		desc29: '',
		desc30: '',
		desc31: '',
		desc32: '',
		desc33: '',
		desc34: '',
		desc35: '',
		desc36: '',
		desc37: '',
		desc38: '',
		desc39: '',
		desc40: '',
		desc41: '',
		desc42: '',
		desc43: '',
		desc44: '',
		desc45: '',
		desc46: '',
		desc47: '',
		desc48: '',
		desc49: '',
	}
};
export default zh;