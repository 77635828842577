
import { createApp } from 'vue'
import store from './store'
import i18n from './public/lang/i18n'
import App from './App.vue'
import router from './router'
import './public/css/common.css'
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
// import "babel-polyfill"
// import 'es6-promise/auto'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import zhTw from 'element-plus/es/locale/lang/zh-tw'
import 'animate.css'
import "./public/js/rem.js";
import "./public/css/font.css";
import glComponent from './components'
// import directive from './utils/directive'

const app = createApp(App)
// directive(app)
app.config.globalProperties = {
}
let lang = localStorage.getItem('language');
store.commit('SETLANG',lang)
let langMap = {
  'zh_CN': zhCn,
  en,
  'zh': zhTw
}

app.use(router)
  .use(i18n)
  .use(store)
  .use(glComponent)
  .use(ElementPlus, {
    locale: langMap[lang],
  })
  .mount('#app')
