import {
  defineAsyncComponent
} from 'vue'
import GlHeader from './header.vue'
import GlNav from './nav.vue'
import PageScroll from './PageScroll.vue'


const components = {
  'gl-header': GlHeader,
  'gl-nav': GlNav,
  'page-scroll': PageScroll,
}


export default {
  install(app) {
    Object.keys(components).forEach(key => {
      app.component(key, components[key])
    })
  }
}