//langs/index.js
// import elementEnLocale from 'element-plus/lib/locale/lang/en'
import elementZhTWLocale from 'element-plus/lib/locale/lang/zh-tw'
// import enLocale  from './en'
import zhLocale  from './zh'
export default {
  // en_US:{
  //   ...enLocale,
  //   ...elementEnLocale
  // },
  zh_TW:{
    ...elementZhTWLocale,
    ...zhLocale
  }
}
