
import store from '@/store'
function isMobileFn() {
  let flag =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const clientWidth = document.documentElement.clientWidth;
  if (clientWidth < 750) {
    flag = true
  }
  return flag;
}
(function (doc, win) {
  const docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = () => {
      const clientWidth = docEl.clientWidth;
      store.commit('SETISMOBILE',isMobileFn())
      if (!isMobileFn()) {
        docEl.style.fontSize = `${clientWidth * 100 / 1920}px`
        // if (clientWidth < 1200) docEl.style.fontSize = `${1200 * 100 / 1920}px`
      } else {
        docEl.style.fontSize = `${clientWidth * 100 / 430}px`
      }
    };
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);





