<template>
  <div class="nav" :class="[isNavExShow?isMobile?'nav-mobile-ex':'nav-ex':'']">
    <div class="close" @click="closeNav"></div>
    <div class="logo" @click="$router.push('/home')">
      <img src="../public/img/logo.png" alt="">
      <img src="../public/img/logo-name-white.png" alt="">
    </div>
    <div class="list">
      <div class="item" @click="goUrl(1)">{{ $t('nav.desc') }}</div>
      <!-- <div class="item" @click="goUrl(2)">{{ $t('nav.desc1') }}</div> -->
      <div class="item" @click="goUrl(3)">{{ $t('nav.desc2') }}</div>
      <div class="item" @click="goUrl(4)">{{ $t('nav.desc3') }}</div>
      <div class="item" @click="goPolicy">{{ $t('nav.desc4') }}</div>
    </div>
    <!-- <div class="lang">
      <div class="lang-title">{{ $t('nav.desc5') }}</div>
      <div class="lang-ex">
        <div class="img" @click="handlerLang('en_US')">
          <img v-if="langName == 'en_US'" src="../public/img/h5/en-on.png" alt="">
          <img v-else src="../public/img/h5/en.png" alt="">
        </div>
        <div class="img" @click="handlerLang('zh_TW')">
          <img v-if="langName == 'zh_TW'" src="../public/img/h5/cn-on.png" alt="">
          <img v-else src="../public/img/h5/cn.png" alt="">
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import store from '../store'
export default {
  inject: ["reload"],
  props: {
    title: String,
  },
  data() {
    return {
      langName: 'en_US',
    }
  },
  mounted() {
    if (localStorage.getItem('language')) {
      this.langName = localStorage.getItem('language')
    }
  },
  methods: {
    goPolicy() {
      this.closeNav()
      this.$router.push('/policy')
    },
    goUrl(num) {
      this.closeNav()
      if (this.title == 'home') {
        this.$emit('goCore',num)
      } else {
        store.commit('SETACTIVEINDEX',num)
        this.$router.push('/home')
      }
    },
    handlerLang(val) {
      store.commit('SETLANG',val)
      localStorage.setItem('language', val)
      this.langName = val
      this.$i18n.locale = val
      // this.reload()
    },
    closeNav() {
      this.$store.commit('SETNAVSHOW',false)
    }
  }
}
</script>
<style lang="less" scoped>
.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2D2D2D;
  z-index: 99;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .close {
    position: absolute;
    top: 0.23rem;
    right: 0.22rem;
    width: 0.25rem;
    height: 0.22rem;
    background: url('../public/img/h5/x.png') no-repeat;
    background-size: 100% 100%;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 0.43rem;
      height: auto;
      &:nth-child(2) {
        width: 1.3rem;
        margin-left: 0.07rem;
      }
    }
  }
  .list {
    .item {
      width: 100%;
      font-family: Arboria;
      font-size: 0.25rem;
      font-weight: 500;
      line-height: 0.3rem;
      letter-spacing: 0em;
      text-align: center;
      color: #757575;
      margin-bottom: 0.51rem;
      &:last-child {margin-bottom: 0;}
    }
  }
  .lang-title {
    font-family: Arboria;
    font-size: 0.15rem;
    font-weight: 400;
    line-height: 0.2rem;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 0.11rem;
    color: #EDEDED;
  }
  .lang-ex {
    display: flex;
    justify-content: center;
    align-items: center;
    .img {
      &:nth-child(2) {
        margin-left: 0.12rem;
      }
      img {
        width: auto;
        height: 0.22rem;
      }
    }
  }
}
</style>
