import {
  createRouter,
  createWebHashHistory
  // createWebHistory
} from "vue-router";
// import i18n from '../public/lang/i18n'
const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 }
  },
  routes: [{
    path: "/home",
    meta: {
      title: "Home"
    },
    component: () => import("@/views/home")
  },
  {
    path: "/policy",
    meta: {
      title: "Privacy Policy"
    },
    component: () => import("@/views/policy")
  },
  {
    //路由重定向
    path: "/:pathMatch(.*)*",
    redirect: "/home"
  }
  ]
});
router.afterEach(() => {
  window.scrollTo(0, 0)
});
router.beforeEach((to, from, next) => {
  // document.title = `${i18n.global.t(to.meta.title)}`
  document.title = to.meta.title
  next();
});
export default router;